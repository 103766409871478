import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-lubbock-texas.png'
import image0 from "../../images/cities/scale-model-of-mackenzie-park-in-lubbock-texas.png"
import image1 from "../../images/cities/scale-model-of-buddy-holly-center-in-lubbock-texas.png"
import image2 from "../../images/cities/scale-model-of-buddy-holly-statue-and-west-texas-walk-of-fame-in-lubbock-texas.png"
import image3 from "../../images/cities/scale-model-of-episcopal-diocese-of-northwest-texas-in-lubbock-texas.png"
import image4 from "../../images/cities/scale-model-of-d-j-tours-in-lubbock-texas.png"
import image5 from "../../images/cities/scale-model-of-halls-of-horror-in-lubbock-texas.png"
import image6 from "../../images/cities/scale-model-of-foerster-travel,-inc.-in-lubbock-texas.png"
import image7 from "../../images/cities/scale-model-of-lubbock-convention-&-visitors-in-lubbock-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Lubbock'
            state='Texas'
            image={image}
            lat='33.5778631'
            lon='-101.8551665'
            attractions={ [{"name": "Mackenzie Park", "vicinity": "301 I-27, Lubbock", "types": ["park", "point_of_interest", "establishment"], "lat": 33.5995451, "lng": -101.83645960000001}, {"name": "Buddy Holly Center", "vicinity": "1801 Crickets Ave, Lubbock", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.5785075, "lng": -101.8426488}, {"name": "Buddy Holly Statue and West Texas Walk of Fame", "vicinity": "1824 Crickets Ave, Lubbock", "types": ["point_of_interest", "establishment"], "lat": 33.5781916, "lng": -101.84351229999999}, {"name": "Episcopal Diocese of Northwest Texas", "vicinity": "1802 Broadway St, Lubbock", "types": ["church", "place_of_worship", "point_of_interest", "establishment"], "lat": 33.584863, "lng": -101.856741}, {"name": "D J Tours", "vicinity": "2607 49th St, Lubbock", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 33.549505, "lng": -101.873378}, {"name": "Halls of Horror", "vicinity": "1201 84th St, Lubbock", "types": ["point_of_interest", "establishment"], "lat": 33.5158565, "lng": -101.84873879999998}, {"name": "Foerster Travel, Inc.", "vicinity": "7021 Kewanee Avenue #7-102, Lubbock", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 33.531051, "lng": -101.9549227}, {"name": "Lubbock Convention & Visitors", "vicinity": "1500 Broadway St # 6, Lubbock", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 33.5849305, "lng": -101.85184099999998}] }
            attractionImages={ {"Mackenzie Park":image0,"Buddy Holly Center":image1,"Buddy Holly Statue and West Texas Walk of Fame":image2,"Episcopal Diocese of Northwest Texas":image3,"D J Tours":image4,"Halls of Horror":image5,"Foerster Travel, Inc.":image6,"Lubbock Convention & Visitors":image7,} }
       />);
  }
}